import React from 'react';
import { graphql, Link as GatsbyLink, PageRendererProps } from 'gatsby';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Link } from '@material-ui/core';
import classNames from 'classnames';
import { categoryStyle } from '../assets/jss/pages/categoryStyle';
import { DefaultHeader } from '../components/Header/Header';
import { Parallax } from '../components/Parallax/Parallax';
import { Breadcrumbs } from '../components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { CategoryNode, ProductNode } from '../interfaces/contentful';
import { Card } from '../components/Card/Card';
import { CardHeader } from '../components/Card/CardHeader';
import { CardBody } from '../components/Card/CardBody';
import { Price } from '../components/Price/Price';
import { AddToCartButton } from '../components/Button/AddToCartButton';
import { useCookies } from 'react-cookie';
import { createCategoryTree, findCategoryBySlugInCategoryTree } from '../helpers/treeHelper';
import backgroundImage from '../assets/img/bg9.jpg';
import { GatsbyImage } from 'gatsby-plugin-image';

interface CategoryPageProps extends PageRendererProps {
  data: {
    categories: {
      edges: { node: CategoryNode }[];
    };
    category: {
      contentful_id: string;
      title: string;
      slug: string;
      products: ProductNode[];
    };
  };
}

export const categoryQuery = graphql`
  query($slug: String!, $locale: String!) {
    category: contentfulCategory(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      contentful_id
      title
      slug
      products: regionalproduct {
        contentful_id
        title
        slug
        price
        unit
        unitValue
        coverImage {
          title
          gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
        }
        description {
          description
        }
        suppliedBy {
          name
          slug
          location {
            lat
            lon
          }
        }
        notAvailableReason
        invisible
      }
    }
    categories: allContentfulCategory {
      edges {
        node {
          slug
          title
          contentful_id
          parentCategory {
            title
            slug
            contentful_id
          }
          regionalproduct {
            contentful_id
            title
            slug
            price
            unit
            unitValue
            coverImage {
              title
              gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
            }
            description {
              description
            }
            suppliedBy {
              name
              slug
              location {
                lat
                lon
              }
            }
            notAvailableReason
            invisible
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(categoryStyle);

// TODO: here we display by appearing at, for each month one big container, inside, the tiles go in rows, similar like in main page for the latest, so 6 per row
const CategoryPageTemplate: React.FC<CategoryPageProps> = ({ data }) => {
  const { category, categories } = data;

  const [cookies] = useCookies(['SeeInvisible']);
  if (!cookies['SeeInvisible'] && category.products) {
    category.products = category.products.filter((product) => product.invisible !== true);
  }

  const categoryTree = createCategoryTree(categories);

  const currentCategory = findCategoryBySlugInCategoryTree(category.slug, categoryTree);

  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.categoryPage}>
      <Helmet>
        <title>{category.title}</title>
      </Helmet>
      <DefaultHeader categoryTree={categoryTree} />

      <Parallax image={backgroundImage} className={classes.parallax}>
        <div className={classes.container}>
          <Breadcrumbs>
            {currentCategory?.parentCategory && (
              <Link
                component={GatsbyLink}
                to={`/categories/${currentCategory.parentCategory.slug}`}
                className={classes.link}
              >
                <Typography>{currentCategory.parentCategory.title}</Typography>
              </Link>
            )}
            <Typography color="secondary">{category.title}</Typography>
          </Breadcrumbs>
        </div>
      </Parallax>

      <div className={classNames(classes.section)}>
        <div className={classes.container}>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Typography variant="h5" component="h2" gutterBottom>
              {category.title}
            </Typography>

            <Grid container justify="flex-start" spacing={1}>
              {!category.products && <div>Geen products, maar misschie wel in ondercategories:</div>}
              {category.products &&
                category.products.map((node) => {
                  return (
                    <Grid item key={node.contentful_id} md={2} sm={4} xs={6}>
                      <Card product>
                        <CardHeader image plain style={{ textAlign: 'center', padding: '10px 10px 0 10px' }}>
                          <GatsbyLink to={`/products/${node.slug}`}>
                            <GatsbyImage
                              alt={node.coverImage.title ? node.coverImage.title : ''}
                              image={node.coverImage.gatsbyImageData}
                            />
                          </GatsbyLink>
                          <span className={classes.textBySupplier}>{node.suppliedBy.name}</span>
                        </CardHeader>
                        <CardBody className={classes.textCenter} plain style={{ paddingTop: 0 }}>
                          <h4 className={classes.cardTitle} style={{ minHeight: '40px' }}>
                            <GatsbyLink to={`/products/${node.slug}`}>{node.title}</GatsbyLink>
                            <br />
                            <span className={classes.textMuted}>
                              ({node.unit}: {node.unitValue})
                            </span>
                          </h4>
                          <br />
                          <Price product={node} />
                          <br />
                          <AddToCartButton product={node} quantity={1} size="sm" onlyIcon={true} />
                        </CardBody>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>

            <Grid container justify="flex-start" spacing={1}>
              {currentCategory?.childNodes &&
                currentCategory.childNodes.map((catNode) => {
                  return (
                    <Grid item key={catNode.contentful_id} xs={12}>
                      <h2>
                        Subcategorie:&nbsp;<GatsbyLink to={`/categories/${catNode.slug}`}>{catNode.title}</GatsbyLink>
                      </h2>

                      <Grid container justify="flex-start" spacing={1}>
                        {catNode.regionalproduct &&
                          catNode.regionalproduct
                            .filter((product) => product.invisible !== true)
                            .map((product) => {
                              return (
                                <Grid item key={product.contentful_id} md={2} sm={4} xs={6}>
                                  <Card product>
                                    <CardHeader
                                      image
                                      plain
                                      style={{ textAlign: 'center', padding: '10px 10px 0 10px' }}
                                    >
                                      <GatsbyLink to={`/products/${product.slug}`}>
                                        <GatsbyImage
                                          alt={product.coverImage.title ? product.coverImage.title : ''}
                                          image={product.coverImage.gatsbyImageData}
                                        />
                                      </GatsbyLink>
                                      <span className={classes.textBySupplier}>{product.suppliedBy.name}</span>
                                    </CardHeader>
                                    <CardBody className={classes.textCenter} plain style={{ paddingTop: 0 }}>
                                      <h4 className={classes.cardTitle} style={{ minHeight: '40px' }}>
                                        <GatsbyLink to={`/products/${product.slug}`}>{product.title}</GatsbyLink>
                                        <br />
                                        <span className={classes.textMuted}>
                                          ({product.unit}: {product.unitValue})
                                        </span>
                                      </h4>
                                      <br />
                                      <Price product={product} />
                                      <br />
                                      <AddToCartButton product={product} quantity={1} size="sm" onlyIcon={true} />
                                    </CardBody>
                                  </Card>
                                </Grid>
                              );
                            })}
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryPageTemplate;
